import React, { useContext } from 'react';
import BotContext from '../BotContext';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const BotSelector = ({ onClearMessages }) => {
  const { selectedBot, setSelectedBot } = useContext(BotContext);

  const handleSelectBot = (botType) => {
    setSelectedBot(botType);
    if (onClearMessages.current) {
      onClearMessages.current();
    }
  };


  return (
    <DropdownButton id="dropdown-basic-button" title="Select Bot" variant="success">
      <Dropdown.Item onClick={() => handleSelectBot('general')}>General Knowledge</Dropdown.Item>
      <Dropdown.Item onClick={() => handleSelectBot('tech')}>Tech Support</Dropdown.Item>
      <Dropdown.Item onClick={() => handleSelectBot('weather')}>Weather</Dropdown.Item>
      <Dropdown.Item onClick={() => handleSelectBot('sports')}>Sports</Dropdown.Item>
      <Dropdown.Item onClick={() => handleSelectBot('health')}>Health</Dropdown.Item>
      <Dropdown.Item onClick={() => handleSelectBot('finance')}>Finance</Dropdown.Item>
    </DropdownButton>
    // <div className="dropdown">
    //   <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //     Select Bot
    //   </button>
    //   <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    //     <button className="dropdown-item" onClick={() => handleSelectBot('general')}>General Knowledge</button>
    //     <button className="dropdown-item" onClick={() => handleSelectBot('tech')}>Tech Support</button>
    //     <button className="dropdown-item" onClick={() => handleSelectBot('weather')}>Weather</button>
    //     <button className="dropdown-item" onClick={() => handleSelectBot('sports')}>Sports</button>
    //     <button className="dropdown-item" onClick={() => handleSelectBot('health')}>Health</button>
    //     <button className="dropdown-item" onClick={() => handleSelectBot('finance')}>Finance</button>
    //   </div>
    // </div>
  );
};

export default BotSelector;
