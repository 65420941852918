import React, { useContext, useState, useEffect } from 'react';
import BotContext from '../BotContext';
import axios from '../axios';
import { Spinner } from 'react-bootstrap';

const ChatInterface = ({ onClearMessages }) => {
  const { selectedBot } = useContext(BotContext);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onClearMessages.current = () => setMessages([]);
  }, [onClearMessages]);

  const sendMessage = async () => {
    setLoading(true);
    const response = await axios.post('/chat', {
      category: selectedBot,
      message: input,
    });
    setMessages([...messages, { user: input, bot: response.data }]);
    setInput('');
    setLoading(false);
  };

  return (

    <div>
      <h5>Selected Bot: {selectedBot.charAt(0).toUpperCase() + selectedBot.slice(1)}</h5>
      <div className="messages bg-light p-3 border rounded mb-3" style={{ height: '550px', overflowY: 'scroll' }}>
        {messages.map((msg, index) => (
          <div key={index}>
            <div class="text-end"><strong>User:</strong> {msg.user}</div>
            <div class="mb-4"><strong>Bot:</strong> {msg.bot}</div>
          </div>
        ))}
        {loading && (
          <div className="d-flex align-items-center">
            <strong>Bot:</strong>
            <div className="spinner-border ml-2" role="status">
            {/* <Spinner animation="border" role="status"> */}
              <span className="sr-only">Loading...</span>
              {/* </Spinner> */}
            </div>
          </div>
        )}
      </div>
      <div className="input-group mb-3">
        <input 
          value={input} 
          onChange={(e) => setInput(e.target.value)} 
          className="form-control" 
          placeholder="Type your message..." 
        />
        <div className="input-group-append">
          <button onClick={sendMessage} className="btn btn-primary">Send</button>
        </div>
      </div>
    </div>


    // <div>
    //   <h5>Selected Bot: {selectedBot.charAt(0).toUpperCase() + selectedBot.slice(1)}</h5>
      
    //   <div className="messages bg-light p-3 border rounded mb-3" style={{ height: '300px', overflowY: 'scroll' }}>
    //     {messages.map((msg, index) => (
    //       <div key={index}>
    //         <div><strong>User:</strong> {msg.user}</div>
    //         <div><strong>Bot:</strong> {msg.bot}</div>
    //       </div>
    //     ))}
    //     {loading && (
    //       <div className="d-flex justify-content-center">
    //         <Spinner animation="border" role="status">
    //           <span className="sr-only">Loading...</span>
    //         </Spinner>
    //       </div>
    //     )}
    //   </div>
    //   <div className="input-group mb-3">
    //     <input 
    //       value={input} 
    //       onChange={(e) => setInput(e.target.value)} 
    //       className="form-control" 
    //       placeholder="Type your message..." 
    //       disabled={loading}
    //     />
    //     <div className="input-group-append">
    //       <button onClick={sendMessage} className="btn btn-primary" disabled={loading}>
    //         Send
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ChatInterface;
