import React, { useRef } from 'react';
import { BotProvider } from './BotContext';
import BotSelector from './components/BotSelector';
import ChatInterface from './components/ChatInterface';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const clearMessagesRef = useRef(null);
  return (
    <BotProvider>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
          <a className="navbar-brand mx-auto text-light" href="#">Chatbot Application</a>
        </nav>
        <div className="row mt-4 ">
          <div className="col-md-12 mb-4">
            <BotSelector onClearMessages={clearMessagesRef} />
          </div>
          <div className="col-md-12">
            <ChatInterface onClearMessages={clearMessagesRef} />
          </div>
        </div>
      </div>
    </BotProvider>
  );
}

export default App;
