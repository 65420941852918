import React, { createContext, useState } from 'react';

const BotContext = createContext();

export const BotProvider = ({ children }) => {
  const [selectedBot, setSelectedBot] = useState('general');

  return (
    <BotContext.Provider value={{ selectedBot, setSelectedBot }}>
      {children}
    </BotContext.Provider>
  );
};

export default BotContext;
